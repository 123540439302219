.quotes {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}
.quote {
  width: 80vw;
  font-size: 1em;
}
.dummy-head {
  padding-left: 5vw;
  width: 100%;
}
.quotename {
  float: right;
  font-size: 1rem;
  font-style: italic;
}
.quotetext {
  width:80vw;
  padding: 0 10vw;
}
.quotetext p{
  width: 70vw;
}

@media only screen and (min-width: 800px) {
  .dummy {
    width: 40vw;
  }
  .quote {
    font-size: 1.5rem;
  }
}
