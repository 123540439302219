.contact{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5vh 4vw 0 4vw;
}
.contact h2{
    font-family: 'Raleway';
    font-size: 2rem;
}

@media only screen and (min-width:800px) {
    .svg-call{
        width:40vw;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .svg-call svg{
        width: 30vw;
    }
    .contact{
        padding: 5vh 5vw 0 5vw;
        height: 100vh;
    }
    .contact h2{
        font-size: 3rem;
    }
}